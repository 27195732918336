<template>
  <main class="navbar-offset-tablet salon-flow">
    <PageNavigator
      :page-title="
        isNewRecord ? $t('products_create.breadcrumbs-list-label') : $t('products_edit.breadcrumbs-list-label')
      "
      :has-back="true"
      :button-back-link="lastCalandarPath ? lastCalandarPath : '/products'"
    >
      <template v-slot:action>
        <div class="d-flex align-items-center">
          <button v-if="productId" class="btn btn-checkbox px-1-5 py-0-8 mr-1-5" @click="deleteProduct()">
            <font-awesome-icon :icon="['fas', 'trash-alt']" class="fa-w-14 mr-0-5"></font-awesome-icon>

            <span class="font-weight-regular roboto-font text-black">{{ $t('common.delete-btn') }}</span>
          </button>

          <button class="btn btn-primary px-1 px-md-4 py-1" @click="submitForm">
            <span class="font-weight-medium poppins-font">{{ $t('common.submit-btn') }}</span>
          </button>
        </div>
      </template>
    </PageNavigator>

    <div class="full-page-height">
      <div class="row pt-7-5">
        <form class="col-12">
          <div class="row">
            <div class="col-12 px-2-5">
              <div class="row">
                <div class="col-12">
                  <p class="text-black poppins-font font-weight-medium">
                    {{ $t('products.labels.section_title_1') }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div
                  v-for="(topField, key) in topFieldsForm"
                  :key="`field-${key}`"
                  :class="topField.containerClass"
                  class="mb-1-5"
                >
                  <div>
                    <label
                      :for="`${topField.name}`"
                      class="poppins-font text-black mb-0-5 font-weight-medium text-small"
                    >
                      {{ $t(topField.i18KeyLabel) }}
                    </label>

                    <input
                      v-if="topField.tag === 'input' && topField.type === 'text'"
                      :id="topField.name"
                      v-model="topField.value"
                      v-validate="topField.validationRules"
                      :data-vv-as="$t(topField.i18KeyLabel)"
                      :name="topField.name"
                      :type="topField.type"
                      :placeholder="$t(topField.i18KeyPlaceholder)"
                      class="form-control w-100 px-1-5"
                    />

                    <Multiselect
                      v-if="topField.tag === 'select'"
                      v-model="topField.value"
                      v-validate="topField.validationRules"
                      :name="topField.name"
                      :data-vv-as="$t(topField.i18KeyLabel)"
                      :options="formattedCategories"
                      label="label"
                      :searchable="false"
                      :select-label="''"
                      :hide-selected="true"
                      track-by="id"
                      :placeholder="$t('products.table_header.select_category')"
                      @select="selectCategory"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ $t(props.option.title) }}</span>
                        </span>
                      </template>
                      <template slot="caret" slot-scope="{ option, toggle }">
                        <div
                          class="multiselect__select d-flex align-items-center justify-content-center"
                          @mousedown.prevent.stop="toggle()"
                        >
                          <font-awesome-icon :icon="['far', 'angle-down']"></font-awesome-icon>
                        </div>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ $t(props.option.title) }}</span>
                        </div>
                      </template>
                    </Multiselect>

                    <div v-if="topField.tag === 'input' && topField.type === 'number'" class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text text-gray bg-white px-0-5 py-0-8 text-small font-weight-medium">
                          CHF
                        </span>
                      </div>
                      <input
                        :id="topField.name"
                        v-model="topField.value"
                        v-validate="topField.validationRules"
                        :min="topField.min"
                        :max="topField.max"
                        :step="topField.step"
                        :data-vv-as="$t(topField.i18KeyLabel)"
                        :name="topField.name"
                        :type="topField.type"
                        :placeholder="topField.placeholder"
                        class="form-control w-100 px-1-5"
                      />
                    </div>

                    <span v-if="errors.has(topField.name)" class="error-message mt-1 col-12 pl-0">
                      {{ errors.first(topField.name) }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row mx-n-1-5 mb-1">
                <div class="col-12 px-1-5">
                  <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">
                    {{ $t('products.labels.product_images') }}
                  </label>
                </div>
                <div
                  v-for="key in Array.from(Array(5).keys())"
                  :key="`image-${key}`"
                  class="col-12 col-sm-6 col-md px-1-5"
                >
                  <div
                    class="position-relative d-flex justify-content-center align-items-center edit-profile-img product-img mb-2-5 w-100 mw-100"
                  >
                    <img
                      v-if="images[key]"
                      style="width: 100%; object-fit: cover;z-index: 2;"
                      :src="images[key].path"
                      alt=""
                      class="mw-100 mh-100 position-relative"
                    />
                    <font-awesome-icon
                      v-else
                      :icon="['far', 'image']"
                      class="fa-w-60 text-white"
                      style="font-size: 60px;z-index:4;"
                    ></font-awesome-icon>

                    <a
                      v-if="images[key]"
                      href="#"
                      class="delete-img-btn position-absolute btn btn-danger px-0-5"
                      @click.prevent="deleteProductImage(key)"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'trash-alt']"
                        class="fa-w-14"
                        style="font-size: 18px;"
                      ></font-awesome-icon>
                    </a>

                    <div class="position-absolute">
                      <input
                        :id="`productImage-${key}`"
                        ref="productImage"
                        type="file"
                        accept="image/x-png,image/jpg,image/jpeg"
                        class="custom-file-upload"
                        @change="onUploadProductImage($event, key)"
                      />
                      <span class="photocamera-icon"></span>
                      <label
                        :class="images[key] ? 'bg-transparent border-0' : ''"
                        class="custom-file-label doc-image"
                        :for="`productImage-${key}`"
                        style="z-index: 3;"
                      >
                        <span class="photocamera-icon"></span
                      ></label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div
                  v-for="(bottomField, key) in bottomFieldsForm"
                  :key="`bottom-field-${key}`"
                  :class="bottomField.containerClass"
                  class="mb-1-5"
                >
                  <div>
                    <label
                      :for="`${bottomField.name}`"
                      class="poppins-font text-black mb-0-5 font-weight-medium text-small"
                    >
                      {{ $t(bottomField.i18KeyLabel) }}
                    </label>

                    <input
                      v-if="bottomField.tag === 'input' && bottomField.type === 'text'"
                      :id="bottomField.name"
                      v-model="bottomField.value"
                      v-validate="bottomField.validationRules"
                      :data-vv-as="$t(bottomField.i18KeyLabel)"
                      :name="bottomField.name"
                      :type="bottomField.type"
                      :placeholder="$t(bottomField.i18KeyPlaceholder)"
                      class="form-control w-100 px-1-5"
                    />

                    <textarea
                      v-if="bottomField.tag === 'textarea'"
                      v-model="bottomField.value"
                      v-validate="bottomField.validationRules"
                      :data-vv-as="$t(bottomField.i18KeyLabel)"
                      :name="bottomField.name"
                      class="form-control w-100 px-1-5"
                      rows="8"
                    ></textarea>

                    <span v-if="errors.has(bottomField.name)" class="error-message mt-1 col-12 pl-0">
                      {{ errors.first(bottomField.name) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div
              class="col-12 d-flex w-100 form-group mb-1-5 px-0 py-1-5 form-check justify-content-center px-2-5 border-top border-bottom"
            >
              <div class="d-flex flex-column align-items-center">
                <div class="mb-1">
                  <div class="checkbox" style="width: 100%; height: 24px;left: -12px;">
                    <input v-model="hasVariations" type="checkbox" @change="selectVariationsType($event)" />
                    <i class="input-helper"></i>
                  </div>
                </div>
                <p class="text-black mb-0 poppins-font">
                  <span v-html="$t('products.labels.section_title_2')"></span>
                </p>
              </div>
            </div>
          </div>
          <div v-if="hasVariations" class="row">
            <div class="col-12 px-2-5">
              <p class="text-black poppins-font font-weight-medium mb-3">
                {{ $t('products.labels.product_variations') }}
              </p>
            </div>

            <div class="col-12 px-2-5">
              <div v-for="(variation, key) in variations" :key="`variation-${key}`" class="row mb-2-5 mb-sm-4">
                <div class="col-auto">
                  <div
                    class="position-relative d-flex justify-content-center align-items-center variation-uploader-block"
                    :class="variation.image.path ? 'bg-trasparent' : ''"
                  >
                    <img
                      v-if="variation.image.path"
                      style="width: 100%; height: 100%; object-fit: cover;z-index: 2;"
                      :src="variation.image.path"
                      alt=""
                      class="mw-100 mh-100 position-relative"
                    />

                    <font-awesome-icon
                      v-else
                      :icon="['far', 'image']"
                      class="fa-w-24 text-white"
                      style="font-size: 24px;"
                    ></font-awesome-icon>

                    <div class="position-absolute w-100 h-100">
                      <input
                        :id="`variationImage-${key}`"
                        ref="variationImage"
                        type="file"
                        accept="image/x-png,image/jpg,image/jpeg"
                        class="custom-file-upload"
                        @change="onUploadVariationImage($event, key)"
                      />

                      <label
                        :class="variation.image.path ? 'bg-transparent border-0' : ''"
                        class="custom-file-label doc-image p-0"
                        :for="`variationImage-${key}`"
                        style="z-index: 3;"
                      >
                        <span class="photocamera-icon"></span
                      ></label>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm pl-sm-2-5 mt-3 mt-sm-0">
                  <label
                    :for="`variation-descriprion-${key}`"
                    class="poppins-font text-black mb-0-5 font-weight-medium text-small"
                  >
                    {{ $t(variation.field.i18KeyLabel) }}:
                  </label>

                  <input
                    :id="`variation-descriprion-${key}`"
                    v-model="variation.field.value"
                    v-validate="'required'"
                    :data-vv-as="$t(variation.field.i18KeyLabel)"
                    :name="`variation-${key}`"
                    type="text"
                    class="form-control w-100 px-1-5"
                  />

                  <span v-if="`variation-${key}`" class="error-message mt-1 col-12 pl-0">
                    {{ errors.first(`variation-${key}`) }}
                  </span>
                </div>
                <div v-if="variations.length === 1 && key === 0" class="col-auto nav-tabs border-0">
                  <b-button
                    :variant="'checkbox'"
                    class="variation-action-btn active text-nowrap mr-1"
                    @click.prevent="addVariation()"
                  >
                    <span class="text-black mr-0-5">
                      {{ $t('salon-services.dynamic-form-label.add_button') }}
                    </span>
                    <font-awesome-icon :icon="['far', 'plus']" class="fa-w-16 text-primary"></font-awesome-icon>
                  </b-button>

                  <b-button
                    v-if="variation.field.value || variation.image.path"
                    :variant="'checkbox'"
                    class="variation-action-btn py-0-8 w-auto"
                    @click.prevent="resetVariation(key)"
                  >
                    <font-awesome-icon
                      :icon="['far', 'times']"
                      class="fa-w-14"
                      style="font-size: 18px;"
                    ></font-awesome-icon>
                  </b-button>
                </div>

                <div v-if="variations.length > 1 && key === variations.length - 1" class="col-auto nav-tabs border-0">
                  <b-button
                    :variant="'checkbox'"
                    class="variation-action-btn active text-nowrap mr-1"
                    @click.prevent="addVariation()"
                  >
                    <span class="text-black mr-0-5">
                      {{ $t('salon-services.dynamic-form-label.add_button') }}
                    </span>
                    <font-awesome-icon :icon="['far', 'plus']" class="fa-w-16 text-primary"></font-awesome-icon>
                  </b-button>

                  <b-button
                    :variant="'checkbox'"
                    class="variation-action-btn py-0-8 w-auto"
                    @click.prevent="deleteVariation(key)"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'trash-alt']"
                      class="fa-w-14"
                      style="font-size: 18px;"
                    ></font-awesome-icon>
                  </b-button>
                </div>

                <div v-if="variations.length > 1 && key < variations.length - 1" class="col-auto nav-tabs border-0">
                  <b-button
                    :variant="'checkbox'"
                    class="variation-action-btn py-0-8"
                    @click.prevent="deleteVariation(key)"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'trash-alt']"
                      class="fa-w-14"
                      style="font-size: 18px;"
                    ></font-awesome-icon>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import PageNavigator from '@/components/PageNavigator'
import Multiselect from 'vue-multiselect'
import ControlLastCalendarPath from '@/_mixins/ControlLastCalendarPath.vue'

import { mapGetters, mapActions, mapMutations } from 'vuex'
import { mapValues, keyBy } from 'lodash'
import { compressedFile } from '@/_mixins/compressedFile.js'

export default {
  name: 'Form',
  components: {
    PageNavigator,
    Multiselect
  },
  mixins: [compressedFile, ControlLastCalendarPath],
  data() {
    return {
      hasVariations: false,
      topFieldsForm: [
        {
          name: 'title',
          value: '',
          validationRules: 'required|max:100',
          i18KeyLabel: 'products.labels.product_name',
          type: 'text',
          tag: 'input',
          containerClass: 'col-12 col-md-6'
        },
        {
          name: 'category_id',
          value: '',
          validationRules: 'required',
          i18KeyLabel: 'products.labels.product_category',
          type: '',
          tag: 'select',
          i18KeyPlaceholder: '',
          containerClass: 'col-12 col-md-4'
        },
        {
          name: 'price',
          value: '',
          validationRules: 'required|min_value:5|decimal:2',
          i18KeyLabel: 'products.labels.price',
          type: 'number',
          tag: 'input',
          min: 0,
          max: 999.99,
          step: 0.01,
          placeholder: '0.00',
          containerClass: 'col-12 col-md'
        }
      ],
      images: [],
      deletedImages: [],
      bottomFieldsForm: [
        {
          name: 'short_description',
          value: '',
          validationRules: 'required|max:70',
          i18KeyLabel: 'products.labels.short_description',
          type: 'text',
          tag: 'input',
          containerClass: 'col-12'
        },
        {
          name: 'description',
          value: '',
          validationRules: 'required',
          i18KeyLabel: 'products.labels.full_description',
          type: 'text',
          tag: 'textarea',
          containerClass: 'col-12'
        }
      ],
      variations: [],
      deletedVariations: [],
      createSuccessMessage: {
        type: 'Sucess!',
        messageI18Key: 'common.alerts.actions.created'
      },
      updateSuccessMessage: {
        type: 'Sucess!',
        messageI18Key: 'common.alerts.actions.updated'
      },
      deletedSuccessMessage: {
        type: 'Sucess!',
        messageI18Key: 'common.alerts.actions.deleted'
      },
      errorMessage: {
        type: 'Error!',
        messageI18Key: 'common.alerts.actions.global_error'
      }
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      categories: 'categories/getCategoriesForSelect',
      lastCalandarPath: 'calendar/getLastCalandarPath'
    }),
    productId() {
      return this.$route.params.id
    },
    formattedCategories() {
      return this.categories
        ? [...this.categories, { title: this.$t('custom-select-values.add-category'), id: 0 }]
        : [{ title: this.$t('custom-select-values.add-category'), id: 0 }]
    },
    isNewRecord() {
      return !this.productId
    }
  },
  mounted() {
    if (this.accountInfo.salon_id) {
      this.clearRedirectRoutePath()
      this.fetchCategoriesForSelect({ salon_id: this.accountInfo.salon_id })
        .then(result => {
          if (this.productId) {
            this.fetchProduct({ product_id: this.productId })
              .then(result => {
                if (result.variations.length > 0) this.hasVariations = true

                this.setFormData(result)
              })
              .catch(errors => {
                if (errors) {
                  this.setError(this.errorMessage)
                  this.$router.push('/products')
                }
              })
          }
        })
        .catch(errors => {
          if (errors) {
            this.setError(this.errorMessage)
            this.$router.push('/products')
          }
        })
    }
  },
  methods: {
    ...mapActions({
      fetchCategoriesForSelect: 'categories/getAllForSelect',
      create: 'products/create',
      update: 'products/update',
      delete: 'products/deleteProduct',
      fetchProduct: 'products/getOne',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    }),
    ...mapMutations({
      setRedirectRoutePath: 'categories/SET_ROUTE_PATH',
      clearRedirectRoutePath: 'categories/CLEAR_ROUTE_PATH'
    }),
    setFormData(data) {
      for (let [key, value] of Object.entries(data)) {
        this.topFieldsForm.find(field => {
          if (field.name === key) field.value = value

          if (key === 'category' && field.name === 'category_id') {
            field.value = value
          }
        })

        this.bottomFieldsForm.find(field => {
          if (field.name === key) field.value = value
        })

        if (key === 'images') {
          value.forEach(file => {
            let image = {
              id: file.id,
              path: file.path,
              file: null
            }

            this.images.push(image)
          })
        }

        if (key === 'variations') {
          value.forEach(item => {
            let variation = {
              id: item.id,
              image: {
                path: null,
                file: null
              },
              field: {
                i18KeyLabel: 'products.labels.option_name',
                value: item.title
              }
            }

            if (item.avatar) {
              variation.image.path = item.avatar.path
            }

            this.variations.push(variation)
          })
        }
      }
    },
    onUploadProductImage({ target }, index) {
      if (this.images[index]) {
        if (this.images[index].id) {
          this.deletedImages.push(this.images[index].id)
        }
      }

      if (!target.files.length) return
      const reader = new FileReader()
      let image = {
        id: null
      }

      reader.onload = e => {
        this.compressed(target.files[0], 5, 660)
          .then(file => {
            image = {
              ...image,
              ...{ path: e.target.result },
              ...{ file: file }
            }

            if (!this.images[index]) {
              this.images.push(image)
            } else {
              this.$set(this.images, index, image)
            }
          })
          .catch(errors => console.log(errors))
      }

      reader.readAsDataURL(target.files[0])
    },
    onUploadVariationImage({ target }, index) {
      if (!target.files.length) return
      const reader = new FileReader()
      let image = {}

      reader.onload = e => {
        this.compressed(target.files[0], 5, 140)
          .then(file => {
            image = {
              ...image,
              ...{ path: e.target.result },
              ...{ file: file }
            }

            if (this.variations[index]) {
              this.variations[index].image = {
                ...image
              }
            }
          })
          .catch(errors => console.log(errors))
      }

      reader.readAsDataURL(target.files[0])
    },
    deleteProductImage(index) {
      if (this.images[index].id !== undefined && this.images[index].id !== null) {
        this.deletedImages.push(this.images[index].id)
      }

      this.images.splice(index, 1)
    },

    addVariation() {
      let variation = {
        id: null,
        image: {
          path: null,
          file: null
        },
        field: {
          i18KeyLabel: 'Option name',
          value: ''
        }
      }

      this.variations.push(variation)
    },
    deleteVariation(index) {
      if (this.variations[index].id !== undefined && this.variations[index].id !== null) {
        this.deletedVariations.push(this.variations[index].id)
      }

      this.variations.splice(index, 1)
    },
    resetVariation(index) {
      this.deleteVariation(index)

      this.addVariation()
    },
    selectVariationsType(e) {
      this.variations = []

      if (this.hasVariations) {
        this.addVariation()
      }
    },
    deleteProduct() {
      this.delete(this.productId)
        .then(result => {
          this.setSuccess(this.deletedSuccessMessage)
          this.$router.push('/products')
        })
        .catch(errors => {
          this.setError(this.errorMessage)
        })
    },
    collectTopFieldValues() {
      return mapValues(keyBy(this.topFieldsForm, 'name'), 'value')
    },
    collectProductImagesValues() {
      return this.images.map(image => {
        return {
          id: image.id,
          image: image.file
        }
      })
    },
    collectBottomFieldalues() {
      return mapValues(keyBy(this.bottomFieldsForm, 'name'), 'value')
    },
    collectVariationsValues() {
      return this.variations.map(variation => {
        let data = {
          id: variation.id,
          title: variation.field.value
        }

        if (variation.image.file) {
          data = {
            ...data,
            ...{ avatar: variation.image.file }
          }
        }

        return data
      })
    },
    selectCategory(category) {
      if (category.id) {
        this.errors.remove('category_id')
      } else {
        this.setRedirectRoutePath(this.$route.path)
        this.$router.push('/categories/create')
      }
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          let mainPayloadData = {
            ...this.collectTopFieldValues(),
            ...this.collectBottomFieldalues()
          }

          let images = this.collectProductImagesValues()
          let variations = this.collectVariationsValues()

          let formData = new FormData()

          formData.append('salon_id', this.accountInfo.salon_id)

          for (const field in mainPayloadData) {
            if (field === 'category_id') {
              formData.append(field, mainPayloadData[field].id)
            } else {
              formData.append(field, mainPayloadData[field])
            }
          }

          let imageIndex = 0

          if (images.length) {
            for (let i = 0; i < images.length; i++) {
              let file = images[i].image
              imageIndex++

              if (!images[i].id) {
                formData.append('images[' + i + '][image]', file)
              }
            }
          }

          if (this.deletedImages.length) {
            for (var i = 0; i < this.deletedImages.length; i++) {
              let id = this.deletedImages[i]
              formData.append('images[' + imageIndex + '][id]', id)
              formData.append('images[' + imageIndex + '][deleted]', 1)
              imageIndex++
            }
          }

          let variationsIndex = 0

          if (variations.length) {
            for (let i = 0; i < variations.length; i++) {
              let variation = variations[i]
              variationsIndex++

              if (!variation.id) {
                formData.append('variations[' + i + '][title]', variation.title)

                if (variation.avatar) {
                  formData.append('variations[' + i + '][avatar]', variation.avatar)
                }
              }

              if (variation.id) {
                formData.append('variations[' + i + '][id]', variation.id)
                formData.append('variations[' + i + '][title]', variation.title)

                if (variation.avatar) {
                  formData.append('variations[' + i + '][avatar]', variation.avatar)
                }
              }
            }
          }

          if (this.deletedVariations.length) {
            for (var i = 0; i < this.deletedVariations.length; i++) {
              let id = this.deletedVariations[i]

              formData.append('variations[' + variationsIndex + '][id]', id)
              formData.append('variations[' + variationsIndex + '][deleted]', 1)
              variationsIndex++
            }
          }

          if (!this.productId) {
            this.create(formData)
              .then(result => {
                if (result) {
                  this.setSuccess(this.createSuccessMessage)
                  this.$router.push('/products')
                }
              })
              .catch(errors => {
                this.setError(this.errorMessage)
              })
          } else {
            formData.append('product_id', this.productId)

            this.update(formData)
              .then(result => {
                if (result) {
                  this.setSuccess(this.updateSuccessMessage)
                  this.$router.push('/products')
                }
              })
              .catch(errors => {
                this.setError(this.errorMessage)
              })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
